import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
          <div className="row section-head">
            <div className="ten columns">
              <p className="lead">
                Entre em contato:
              </p>
            </div>
          </div>
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h4>insitu.cga@gmail.com</h4>
                <h4>(81) 9 9989 - 2112</h4>
                <h4>(81) 9 8811 - 3568</h4>
                <a href="https://www.instagram.com/insitu.consultoria/" target="_blank">
                  <img src="images/insta-icon.png" height="32px" width="32px"/>
                </a>
              </div>
            </aside>
          </div>
        </section>
        );
  }
}