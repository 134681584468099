import React, { Component } from 'react';
export default class Header extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <React.Fragment>
      
      <header id="home">
         <nav id="nav-wrap">
            <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
          <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
            <ul id="nav" className="nav">
               <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
               <li><a className="smoothscroll" href="#about">Sobre</a></li>
               <li><a className="smoothscroll" href="#resume">Serviços</a></li>
               <li><a className="smoothscroll" href="#portfolio">Clientes</a></li>
               <li><a className="smoothscroll" href="#contact">Contatos</a></li>
            </ul>
         </nav>

         <div className="row banner">
            <div className="banner-text">
               <img className="logo" src="images/logo-insitu-png-test.png" />
               <h3 style={{color:'#fff', fontFamily:'sans-serif '}}>
               Consutoria Geológica e Ambiental
               </h3>
               <hr/>
               <a href="https://www.instagram.com/insitu.consultoria/" target="_blank">
                  <img src="images/insta-icon.png" height="32px" width="32px"/>
               </a>
            </div>
         </div>

         <p className="scrolldown">
            <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
         </p>

      </header>
      </React.Fragment>
    );
  }
}