import React, { Component } from 'react';
export default class Porfolio extends Component {
  render() {
    return (
      <section id="portfolio">
        <div>
          <h2 className="portfolio-h2">Conheça alguns dos nossos clientes:</h2>
        </div>
        <div className="empresas-container">
          <figure>
            <img src="images/empresas/ibi logo.jpg" alt="ibi logo" />	
          </figure>
          <figure>
            <img src="images/empresas/TPF-ENGENHARIA-1.jpg" alt="TPF Engenharia logo" />	
          </figure>
          <figure>
            <img src="images/empresas/future-proman.jpg" alt="Futura Proman logo" />	
          </figure>
          <figure>
            <img src="images/empresas/sts logo.jpg" alt="STS logo" />	
          </figure>
          <figure>
            <img src="images/empresas/AGIS logo.png" alt="STS logo" />	
          </figure>
        </div>
        <div className="galeria">
         <figure >
            <img src="images/foto-insitu/001.jpeg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/002.jpeg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/003.jpeg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/004.jpeg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/005.jpeg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/006.jpeg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/007.jpg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/008.jpg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/009.jpg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/010.jpg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/011.jpg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/012.jpg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/013.jpg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/014.jpg" alt="STS logo" className="galeria-imagem" />	
          </figure>
          <figure >
            <img src="images/foto-insitu/015.jpg" alt="STS logo" className="galeria-imagem" />	
          </figure>
        </div>
      </section>
    );
  }
}