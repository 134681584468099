import React, { Component } from 'react';
export default class About extends Component {
  render() {
    return (
      <section id="about">
         <div className="row">

            <div className="three columns">

               <img className="profile-pic"  src="images/foto-insitu/014.jpg" alt="" />
            </div>

            <div className="nine columns main-col">
               <h2>Missão</h2>
               <p>
                  Prestar serviços de consultoria geológica e ambiental com
                  excelência, segurança e pontualidade a nossos parceiros e clientes.
               </p>
               <div className="row">
                  <div className="columns contact-details">
                     <h2>Visão</h2>
                     <p>
                        Ser referência em sua área de atuação, pela qualidade dos serviços prestados, tendo como foco
                         o monitoramento, a normatização e a segurança de aquisição de dados coletados no campo.
                     </p>
                  </div>
                  <div className="columns contact-details">
                     <h2>Valores</h2>
                     <ul>
                        <li>•	Respeito e ética</li>
                        <li>•	Pontualidade</li>
                        <li>•	Confiabilidade</li>
                        <li>•	Responsabilidade social e ambiental</li>
                        <li>•	Segurança e saúde</li>
                     </ul>
                  </div>
               </div>
               
            </div>
         </div>
      </section>
    );
  }
}