import React from 'react';
import Card from './Card';

export default function Resume () {

  
  return (
    <section id="resume">  
      <div className="resume-content">

        <div className="resume-card" >
          <div className="resume-card-header">
            <h1>Supervisão e Investigação geotécnica</h1>
          </div>
         
          <div className="resume-card-body">
            <ul>
              <li>Sondagem à trado</li>
              <li>Sondagem SPT</li>
              <li>Sondagem rotativa testemunhada</li>
              <li>Ensaio geotécnico</li>
            </ul>
          </div>   
        </div>


        <div className="resume-card">
          <div className="resume-card-header">
            <h1>Elaboração de Relatórios Ambientais</h1>
          </div>
          <div className="resume-card-body">
            <ul>
              <li>Elaboração de estudos de impacto ambiental (EIA)</li>
              <li>Relatório de impacto ambiental (RIMA)</li>
              <li>Relatório ambiental simplificado (RAS)</li>
            </ul>
          </div>
          
        </div>

        <div className="resume-card">
          <div className="resume-card-header">
            <h1>Outorga e manutenção de poços</h1>
          </div>
        </div>

        <div className="resume-card">
          <div className="resume-card-header">
            <h1>Mapeamento Geológico</h1>
          </div>
        </div>

        <div className="resume-card">
          <div className="resume-card-header">
            <h1>Laudos Geotécnicos</h1>
          </div>

        </div>
      </div>
    </section>
  );
}